import Helpers from './helpers';

(($) => {
    $(document).ready(() => {
        const feedForm = document.getElementsByClassName('feed-form');
        if(!feedForm.length) {
            return;
        }

        const urlCheckPattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator

        const feedUrlEl = document.getElementById('feed-url');
		let feedUrl = feedUrlEl.value;
        feedUrlEl.addEventListener('keydown', Helpers.debounce((e) => {
            if(! urlCheckPattern.test(e.target.value)) {
                e.target.classList.add('is-invalid');
            } else {
                e.target.classList.remove('is-invalid');
                const mappingWrapperEl = document.getElementById('feed-mapping-fields');
                if(mappingWrapperEl && feedUrl !== e.target.value) {
					feedUrl = e.target.value;
                    mappingWrapperEl.innerHTML = '<div class="text-center">\n' +
                        '  <div class="spinner-border" role="status">\n' +
                        '  </div>\n' +
                        '</div>';
                    $.post(
                        '/ajax',
                        {
                            action: 'feed_mapping_fields',
                            feed_url: e.target.value,
                            _token: $('meta[name="csrf-token"]').attr('content'),
                        },
                        (res) => {
                            if(!res || !res.success) {
                                return;
                            }

                            if(res.mappingView) {
                                mappingWrapperEl.innerHTML = res.mappingView;
                            }
                        }
                    );
                }
            }
        }, 250));

        const feedMappingFieldsEl = document.getElementById('feed-mapping-fields');
        feedMappingFieldsEl.addEventListener('change', (e) => {
            if(!e.target.classList.contains('fm-o-f')) {
                const otherField = e.target.parentNode.querySelector('.fm-o-f');
                if( otherField ) {
                    if('other' === e.target.value) {
                        otherField.classList.remove('hidden');
                    } else {
                        otherField.classList.add('hidden');
                    }
                }
            }
        });
    });
})(jQuery);
