(($) => {
    $(document).on('click', '.btn-add', function(e)
    {
        e.preventDefault();
        const controlForm = $('#footer-links:first'),
            currentEntry = $(this).parents('.form-row:first'),
            newEntry = $(currentEntry.clone()).appendTo(controlForm);
        newEntry.find('input').val('');
        controlForm.find('.form-row:not(:last) .btn-add')
            .removeClass('btn-add').addClass('btn-remove')
            .removeClass('btn-success').addClass('btn-danger')
            .text('Remove');
    }).on('click', '.btn-remove', function(e)
    {
        e.preventDefault();
        $(this).parents('.form-row:first').remove();
        return false;
    });
})(jQuery);
