/**
 * Setup dropdowns for tracking code
 */
(($, trackingCodeData) => {

    if(!trackingCodeData) {
        return;
    }

    const brandSelector = document.getElementById('tracking-code-brand');
    const newsletterSelector = document.getElementById('tracking-code-newsletter');
    const templateSelector = document.getElementById('tracking-code-template');

    if(!brandSelector || !newsletterSelector || !templateSelector) {
        return;
    }

    const setupBrandListOptions = (brandId) => {
        let brandOptions = '<option value="">(any)</option>';
        brandId = parseInt(brandId);
        if(trackingCodeData && trackingCodeData.brands) {
            trackingCodeData.brands.forEach(brand => {
                const selected = (brand.id === brandId) ? 'selected' : '';
                brandOptions += '<option ' + selected + ' value="' + brand.id + '">' + brand.name + '</option>';
            });
        }

        brandSelector.innerHTML = brandOptions;
    };

    const setupNewsletterListOptions = (newsletterId, brandId) => {
        let newsletterOptions = '<option value="">(any)</option>';
        newsletterId = parseInt(newsletterId);
        brandId = parseInt(brandId);
        if(brandId && trackingCodeData && trackingCodeData.newsletters) {
            trackingCodeData.newsletters.filter(newsletter => newsletter.brand_id === brandId).forEach(newsletter => {
                const selected = (newsletter.id === newsletterId) ? 'selected' : '';
                newsletterOptions += '<option ' + selected + ' value="' + newsletter.id + '">' + newsletter.name + '</option>';
            });
        }

        newsletterSelector.innerHTML = newsletterOptions;
    };

    const setupTemplateListOptions = (templateId, newsletterId) => {
        let templateOptions = '<option value="">(any)</option>';
        templateId = parseInt(templateId);
        newsletterId = parseInt(newsletterId);
        if(newsletterId && trackingCodeData && trackingCodeData.templates) {
            trackingCodeData.templates.filter(template => template.newsletter_id === newsletterId).forEach(template => {
                const selected = (template.id === templateId) ? 'selected' : '';
                templateOptions += '<option ' + selected + ' value="' + template.id + '">' + template.name + '</option>';
            });
        }

        templateSelector.innerHTML = templateOptions;
    };

    let brandId = null;
    const selectedBrandID = document.getElementsByName('selected_brand_id');
    if(selectedBrandID && selectedBrandID.length) {
        brandId = parseInt(selectedBrandID[0].value) || brandId;
    }
    setupBrandListOptions(brandId);

    let newsletterId = null;
    const selectedNewsletterID = document.getElementsByName('selected_newsletter_id');
    if(selectedNewsletterID && selectedNewsletterID.length) {
        newsletterId = parseInt(selectedNewsletterID[0].value) || newsletterId;
    }
    setupNewsletterListOptions(newsletterId, brandId);

    let templateId = null;
    const selectedTemplateID = document.getElementsByName('selected_template_id');
    if(selectedTemplateID && selectedTemplateID.length) {
        templateId = parseInt(selectedTemplateID[0].value) || templateId;
    }
    setupTemplateListOptions(templateId, newsletterId);

    $(document).ready(() => {
        brandSelector.addEventListener('change', (e) => {
            setupNewsletterListOptions(0, e.target.value);
            setupTemplateListOptions();
        });

        newsletterSelector.addEventListener('change', (e) => {
            setupTemplateListOptions(0, e.target.value);
        })
    });
})(jQuery, window.trackingCodeData);
